import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/jobShare',
    name: 'JobShare',
    component: () => import('@/views/jobShare'),
  },
  {
    path: '/homesharingpage',
    name: 'homesharingpage',
    component: () => import('@/views/homesharingpage'),
  },
  {
    path: '/companyShare',
    name: 'CompanyShare',
    component: () => import('@/views/CompanyShare'),
  },
  {
    path: '/deliverresume',
    name: 'deliverresume',
    component: () => import('@/views/deliverresume'),
  },
  {
    path: '/preachShare',
    name: 'PreachShare',
    component: () => import('@/views/PreachShare'),
  },
  {
    path: '/resumeShare',
    name: 'ResumeShare',
    component: () => import('@/views/ResumeShare'),
    alias: ['/stuonlineresume', '/liveResume'],
  },
  {
    path: '/studentsExplain',
    name: 'StudentGuide',
    component: () => import('@/views/Guide'),
  },
  {
    path: '/companyExplain',
    name: 'CompanyGuide',
    component: () => import('@/views/Guide'),
  },
  {
    path: '/guide/show',
    name: 'GuideImage',
    component: () => import('@/views/ShowImg'),
  },
  {
    path: '/helpc/:type',
    name: 'GuidePc',
    component: () => import('@/views/GuidePC'),
  },
  {
    path: '/OfflineUndersOperation',
    name: 'OfflineUndersOperation',
    redirect: `/guide/show?img=${encodeURIComponent(
      'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OfflineUndersOperation/OfflineUndersOperation-34711606964619575.png'
    )}`,
  },
  {
    path: '/OnlineUndersOperation',
    name: 'OnlineUndersOperation',
    redirect: `/guide/show?img=${encodeURIComponent(
      'https://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/%E7%BA%BF%E4%B8%8A%E5%8F%8C%E9%80%89%E4%BC%9A%E6%B5%81%E7%A8%8B%E5%9B%BE.png'
    )}`,
  },
  {
    path: '/OfflineTeachinOperation',
    name: 'OfflineTeachinOperation',
    redirect: `/guide/show?img=${encodeURIComponent(
      'https://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/%E7%BA%BF%E4%B8%8B%E5%AE%A3%E8%AE%B2%E4%BC%9A%E6%B5%81%E7%A8%8B%E5%9B%BE.png'
    )}`,
  },
  {
    path: '/OnlineTeachinOperation',
    name: 'OnlineTeachinOperation',
    redirect: `/guide/show?img=${encodeURIComponent(
      'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OfflineUndersOperation/OfflineUndersOperation-10231606965319025.png'
    )}`,
  },
  {
    path: '/newBanner',
    name: 'NewBanner',
    component: () => import('@/views/Banner'),
  },
  {
    path: '/unders',
    name: 'Under',
    component: () => import('@/views/Under'),
  },
  {
    path: '/school',
    name: 'School',
    component: () => import('@/views/School'),
  },
  {
    path: '/activity10',
    name: 'Activity10',
    component: () => import('@/views/activity_10'),
  },
  {
    path: '/activity10_gift',
    name: 'Activity10Gift',
    component: () => import('@/views/activity_10_gift'),
  },
  {
    path: '/:all(.*)*',
    name: 'NotFound',
    component: () => import('@/views/404.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
