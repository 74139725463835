import { createStore } from 'vuex';

const store = createStore({
  state: {
    loading: '',
  },

  mutations: {
    CHANGE_LOADING(state, val) {
      state.loading = val;
    },
  },

  actions: {
    changeLoading({ commit }, val) {
      commit('CHANGE_LOADING', val || '');
    },
  },

  modules: {},
});

export default store;
